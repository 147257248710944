.listWrapper {
  @apply relative overflow-hidden h-[calc(100%-86px)];

  mask-image: linear-gradient(rgba(0, 0, 0, 1) 55%, rgba(0, 0, 0, 0.3));

  [class~="ps__rail-y"]{
    transform: scaleY(0.75) translateX(-1px);
    transform-origin: top center;
  }
}

.listItem {
  @apply h-66px xs:h-52px shadow-custom-4;
}

.successIcon {
  g, path {
    stroke: #C3C6CA;
  }
}

.successIconActive {
  g, path {
    stroke: #1FAD7B;
  }
}