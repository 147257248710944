.mainIconWrapper {
  position: absolute;
  left: -100px;
  width: calc(100% + 200px);
  bottom: -100px;
  height: 510px;
}

.cloud1 {
  position: absolute;
  top: 2px;
  right: -35px;

  width: 152px;
  height: 152px;

  z-index: 10;
}

.cloud2 {
  position: absolute;
  top: 236px;
  left: -33px;

  width: 96px;
  height: 96px;
}

.cloud3 {
  position: absolute;
  top: 15px;
  right: 152px;

  width: 96px;
  height: 96px;

  filter: blur(5px);
}

.cloud4 {
  position: absolute;
  top: 205px;
  right: -10px;

  width: 66px;
  height: 66px;

  filter: blur(5px);
  opacity: 0.5;
}